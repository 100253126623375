<template>
  <div class="row">
    <div class="col-md-12">
      <div id="filter-card" class="row py-3 px-4">
        <div class="col-lg-12 col-xl-12 px-0">
          <form @submit.prevent="filterResult">
            <div class="row align-items-end py-0 my-0 mx-0">
              <div
                class="col-xl-11 col-lg-11 col-md-9 col-sm-12 my-2 row px-0 mx-0"
              >
                <input-filter
                  :helper-text="$t('general.search')"
                  :model.sync="search"
                  :placeholder="$t('general.search_filter')"
                  title="general.search"
                ></input-filter>
              </div>
              <div
                class="
                  col-xl-1
                  my-lg-1
                  col-md-2 col-sm-12
                  mt-3
                  d-flex
                  align-items-center
                  justify-content-end
                  m-0
                  px-0
                  row
                "
                style="margin: 0 !important"
              >
                <div class="col-12 pr-0">
                  <button
                  type="button"
                    class="
                      w-100
                      btn btn-sm btn-outline-primary
                      rounded-full
                      font-weight-bold
                      py-1
                    "
                    @click="filterResult(null)"
                  >
                    {{ $t("general.filter") }}
                  </button>
                </div>
                <div class="col-12 pr-0">
                  <button
                    class="
                      w-100
                      btn btn-sm btn-outline-primary
                      rounded-full
                      font-weight-bold
                      mt-2
                      py-1
                    "
                    @click="resetFilters"
                  >
                    {{ $t("general.clear") }}
                  </button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>

    <div class="col-md-12">
      <dashboard-box class="pb-10">
        <template v-slot:preview>
          <data-table
            :bordered="false"
            :borderless="true"
            :fields="fields"
            :hover="false"
            :items="formattedItem"
            :no-border-collapse="true"
            :outlined="false"
            :page.sync="page"
            :per-page.sync="per_page"
            :striped="false"
            class="relative"
            tableVariant="''"
            :infiniteId="infiniteId"
            @hitBottom="onHitBottom"
            @onChangeStatus="onChangeStatus"
            @onClickEdit="onClickEdit"
          ></data-table>
          <div class="d-flex justify-content-end items-end fixed cursor-pointer" style="right: 10px; bottom: 10px;">
            <div class="w-60px h-60px"
                 @click="$router.push({name: createRouteName})">
              <span v-html="getIconByKey('icons.waybill.waybill_entry', {
                  class: 'w-60px h-60px d-inline-block object-fill cursor-pointer'
                })">
                </span>
            </div>
          </div>
        </template>
      </dashboard-box>
    </div>
  </div>
</template>
  
  <script>
import DashboardBox from "@/assets/components/DashboardBox";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import DataTable from "@/assets/components/dataTable/DataTable";
import moment from "moment";
import {
  LARAVEL_DATE_TIME_FORMAT,
  MOMENT_SYSTEM_DATE_FORMAT,
} from "@/core/config/constant";
import InputFilter from "@/assets/components/filters/InputFilter";
import SelectFilter from "@/assets/components/filters/SelectFilter";

import baseModule, {
  BASE_URL,
  ERROR,
  HANDLE_INFINITE_SCROLL,
  ITEMS,
  LOADING,
  MODULE_NAME,
  SET_ITEMS,
  SUCCESS,
  UPDATE_ITEM,
} from "@/core/services/store/company/company.module";
import store from "@/core/services/store";

const _MODULE_NAME = MODULE_NAME;

export default {
  name: "CompaniesIndex",
  beforeCreate() {
    function registerStoreModule(moduleName, storeModule) {
      if (!(store && store.state && store.state[moduleName])) {
        store.registerModule(moduleName, storeModule);
      }
    }

    registerStoreModule(_MODULE_NAME, baseModule);
  },
  components: {
    SelectFilter,
    InputFilter,
    DataTable,
    DashboardBox,
  },
  computed: {
    error() {
      return store.getters[_MODULE_NAME + "/" + ERROR];
    },
    loading() {
      return store.getters[_MODULE_NAME + "/" + LOADING];
    },
    success() {
      return store.getters[_MODULE_NAME + "/" + SUCCESS];
    },
    items() {
      return store.getters[_MODULE_NAME + "/" + ITEMS];
    },
    filters() {
      return {
        per_page: this.per_page,
        page: this.page,
        search: this.search,
      };
    },
    formattedItem() {
      let results = [];
      if (
        this.items === null ||
        this.items.data === null ||
        this.items.data === undefined
      )
        return [];
      for (let i = 0; i < this.items.data.length; i++) {
        let currentItem = this.items.data[i];
        let createdAt = currentItem.created_at
          ? moment(currentItem.created_at, LARAVEL_DATE_TIME_FORMAT)
          : moment();
        results.push({
          id: currentItem.id,
          isActive: +currentItem.status === 1,
          name: currentItem.name,
          createdAt: createdAt.format(MOMENT_SYSTEM_DATE_FORMAT),
        });
      }
      return results;
    },
  },
  data() {
    return {
      page: 1,
      per_page: 25,
      search: "",
      state: null,
      infiniteId: 1,
      editRouteName: "management.companies.edit",
      createRouteName: 'management.companies.create',
      languages: [],
      fields: [
        {
          key: "id",
          label: this.$t("general.id"),
          sortable: true,
          class: "text-center border border-top-0 border-right-0 font-size-lg",
        },
        {
          key: "name",
          label: this.$t("general.name"),
          sortable: true,
          class: "text-center border border-top-0 border-right-0 font-size-lg",
        },
        {
          key: "status",
          label: this.$t("general.status"),
          sortable: false,
          class:
            "text-center border border-top-0 border-right-0 font-weight-bolder font-size-lg ",
        },
        {
          key: "createdAt",
          label: this.$t("general.created_at"),
          sortable: false,
          class: "text-center border border-top-0 border-right-0 font-size-lg",
        },
        {
          key: "only_update",
          label: this.$t("general.actions"),
          sortable: false,
          class: "text-center border border-top-0 border-right-0 font-size-lg",
        },
      ],
    };
  },
  methods: {
    onClickEdit(id) {
      this.$router.push({ name: this.editRouteName, params: { id: id } });
    },
    onChangeStatus(id) {
      let item = this.items.data.find((item) => item.id === id);
      item.status = !item.status;

      this.$store.dispatch(_MODULE_NAME + "/" + UPDATE_ITEM, {
        filters: this.filters,
        url: BASE_URL + "/" + id,
        contents: item,
      });
    },
    onHitBottom($state) {
      if (this.loading) {
        return;
      }
      if ($state) {
        this.state = $state;
      }
      this.$store
        .dispatch(_MODULE_NAME + "/" + HANDLE_INFINITE_SCROLL, {
          filters: this.filters,
          url: BASE_URL,
          $state: $state,
        })
        .then((result) => {
          if (result.status && $state) {
            $state.loaded();
          } else {
            if ("end" in result && $state) {
              $state.complete();
            }
          }
        });
    },
    filterResult() {
      let self = this;
      this.page = 1;
      this.filters.page = 1;
      this.infiniteId++;
      this.onHitBottom();
      if (self.state) {
        setTimeout(() => {
          self.state.reset();
        }, 100);
      }
    },
    resetFilters() {
      this.search = "";
    },
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: this.$t("general.dashboard"), route: "dashboard" },
      { title: this.$t("general.companies") },
    ]);
    this.$store.commit(_MODULE_NAME + "/" + SET_ITEMS, null);
    setTimeout(this.onHitBottom, 1000);
  },
};
</script>
  
  <style scoped>
</style>
  